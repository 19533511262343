@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.signup {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
  }

.title {
    /* color: #0099ff;  */
    color: #003E35;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }

.sub_title {
  /* color: #0099ff; */
  color: #003E35 ;
  font-size: 20px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}  