.layout {
  display: grid;
  grid-template-columns: 16rem 1fr;
  grid-template-rows: auto 1fr; /* Ensure the header's height is based on its content */
  height: 100vh;  /* Make the layout fill the viewport height */
}

.header {
  grid-area: 1 / 1 / 2 / 3;
  position: sticky;
  top: 0;
  background-color: #F4F3EE;
  z-index: 100; /* Ensure it's above other elements */
}

.aside {
  grid-area: 2 / 1 / 3 / 2;
  position: sticky;
  top: 0;
  max-height: calc(100vh - 64px);
  min-height: 100vh;   /* Adjust the height based on your header's height */
  overflow-y: auto; /* Add scrolling if needed */
}

.main {
  grid-area: 2 / 2 / 3 / 3;
  overflow-y: auto; /* Add scrolling if needed */
}

.main2 {
  grid-area: 2 / 1 / 3 / 3;
  overflow-y: auto; /* Add scrolling if needed */
}

.footer {
  grid-area: 3 / 2 / 4 / 3;
}

/* Media query for smaller devices */
@media screen and (max-width: 768px) {
  .layout {
    grid-template-columns: 1fr; /* Change to a single column layout for small devices */
    grid-template-rows: auto auto 1fr; /* Adjust rows for small devices */
  }

  .aside {
    grid-area: 2 / 1 / 3 / 2;
    max-height: none; /* Remove max-height for small devices */
    min-height: auto; /* Remove min-height for small devices */
  }

  .main {
    grid-area: 3 / 1 / 4 / 2;
  }

  .main2 {
    grid-area: 4 / 1 / 5 / 2;
  }
}
