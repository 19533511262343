body {
    font-family: Arial, Helvetica, sans-serif;
  }

  .box {
    overflow: scroll;
  }

.form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

.form-inline label {
    margin: 5px 10px 5px 0;
  }

.form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
.button-section {
    margin: 3%;
  }

.button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
  }

.button:hover {
    background-color: royalblue;
  }

.remove {
    background-color: rgb(192, 53, 53);
  }
  
.remove:hover {
    background-color: rgb(187, 43, 43);
  }

  

  @media only screen and (max-width: 600px) {
    .form-inline {
      display: flex;
      flex-direction: row;
      /* border: 1px solid red; */
      background-color: lightblue;
    }
    .form-inline input {
      width: 97px;
    }
    .remove{
     display: flex;
     flex-direction: row; 
    }
    .box {
      overflow: scroll;
    }
  }

  @media only screen and (max-width: 848px) {
    .form-inline {
      display: flex;
      flex-direction: row;
      overflow: scroll;
    }
    .form-inline input {
      width: 97px;
    }
    .remove{
     display: flex;
     flex-direction: row; 
    }
    .box {
      overflow: scroll;
    }
  }


  @media only screen and (max-width: 950px) {
    .form-inline {
      display: flex;
      flex-direction: row;
    }
    .form-inline input {
      width: 97px;
    }
    .remove{
     display: flex;
     flex-direction: row; 
    }
    .box {
      overflow: scroll;
    }
  }

  @media only screen and (max-width: 1008px) {
    .form-inline {
      display: flex;
      flex-direction: row;
    }
    .form-inline input {
      width: 97px;
    }
    .remove{
     display: flex;
     flex-direction: row; 
    }
    .box {
      overflow: scroll;
    }
  }
  