.search-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    min-height: 100vh;
    background: #3b7b6f;
  }
  
  .logo-heading {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
    letter-spacing: 25px;
  }
  
  .search-text {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
  }
  
  .search-bar {
    width: 80%;
    display: flex;
    align-items: center;
  }
  
  .search-button {
    border-radius: 25px;
  }
  