* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cmp_card {
     border: 1px solid #ccc;
     margin-bottom: 1rem;
}

.company_card {
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: auto;
    /* border: 1px solid #ccc; */
    margin-bottom: 1rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.company_name {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    color: black;
}

.text_field {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 400px;
}

.ctn {
    margin: 1.5rem;
}
