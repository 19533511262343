/*** create file ***/
.d-create-file {
    padding: 50px;
    border-radius: 0;
    border: dashed 3px #dddddd;
    text-align: center;
    width: fit-content;
    /* width: 400px; */
}

#get_file{
    margin: 0 auto;
}

.browse{
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
}

#upload_file{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.image_upload {
    /* width: 230px; */
    width: fit-content;
    height: 100px;
    display: grid;
    margin-right: auto;
    margin-left: auto;
}


@media only screen and (max-width: 600px) {
    .d-create-file {
      /* width: 300px; */
      width: max-content;
    }
  }