 /* Welcome.css */
 .contentCard {
  width: 120%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #0D6659;
  opacity: 0.62;
}
 .imageLogo {
  height: 100%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  margin-bottom: 2%;
  /* margin: 20%; */
}
#heading-title {
  color: #000;
  letter-spacing: 8px;
  margin-left: 8px;
}
.welcome-container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/backgrnd.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  opacity: 0.8; /* Adjust the opacity as needed */
}

.centered-content {
  z-index: 1; /* Ensure the centered content is on top of the background image */
  text-align: center;
  color: #0D6659; /* Set text color */
  max-width: 400px; /* Set max width for better visibility on small screens */
  margin: auto; /* Center horizontally */
  padding: 20px; /* Add padding for better spacing */
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
  .centered-content {
    font-size: 1.5em; /* Increase font size on small screens */
  }
  .background-image {
    min-height: 200vh;
  }
  .contentCard {
    width: 100%;
  }
}
