.hero_image {
  /* background-image: url("../../assets/images/VC_ecolabel.png"); */
  background-color: #153327;
  /* height: 600px; */
  /* height: 100%; */
  max-height: fit-content;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 5px solid #003E35;
}

.image_container {
  width: 50%;
  float: left;
  margin-top: 5%;
}

.logo_image {
  width: 80%;
}

.qr_image {
  /* width: 30%; */
  height: 100%;
  float: left;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 7%;
  padding-bottom: 10%;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 10%;
  border: 8px solid white;
  /* background-image: url("../../assets/images/QR.png"); */
  background-size: cover;
}

.hero_text {
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
/* Decrease the font size of the heading */
.heading {
  font-size: 1.5rem;
}

/* Media Queries */
@media screen and (max-width: 600px) {
  .hero_image {
    height: 300px;
  }
}

@media screen and (min-width: 1200px) {
  /* body {
    background-color: lightgreen;
  } */
  .qr_image {
    width: 40%;
    padding-bottom: 10%;
    padding-right: 10%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
  }
  .image_container {
    width: 60%;
    float: left;
    margin-top: 5%;
  }
}