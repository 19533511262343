.item {
    width: 38vw;
}
 
@media only screen and (max-width: 600px) {
    .abc {
      background-color: lightblue;
      display: flex;
      flex-direction: column;
    }
    .product_card {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .info_card {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        float: right;
    } 
  }