/* .menuItems {
    background-color: #fff;
    margin: 1%;
    padding: 1%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 3px rgba(150,168,157,0.72);
} */

.reportHeader {
    color: rgb(49, 84, 49);
    font-size: 1.8em;
    margin-left: 1rem;
    margin-top: 1em;
}

.normalHeader {
    color: rgb(49, 84, 49);
    font-size: 1.8rem;
    margin-left: 1rem;
    margin-top: 3rem;
}

.reportFrontPage {
    /* margin: 5px 10px 100px 5px; */
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    text-wrap: auto;
    font-size: 1em;
}

.reportSubHeader {
    color: rgb(49, 84, 49);
    font-size: 1.6em;
    margin-left: 1rem;
    margin-right: 1rem;
}

.notesHeading {
    color: rgb(49, 84, 49);
    margin-top: 1em;
    margin-left: 1rem;
    margin-right: 1em;
}

.greenColor {
    color: rgb(49, 84, 49);
    margin-top: 2px;
    margin-bottom: 2px;
}

.emissionsTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-left: 1em;
    margin-right: 1em;
}

.tde {
    border: 1px solid #161515;
    text-align: left;
    padding: 8px;
}

.the {
    border: 1px solid #161515;
    text-align: left;
    padding: 8px;
    background-color: rgb(199, 194, 194);
}

.modalSize {
    height: 100vh;
    width: 100vw;
}