.left {
    text-align: left;
    width: 50%;
}
.right {
    text-align: left;
    width: 50%;
}
.left, .right {
    display: inline-block;
    padding-left: 2%;
    padding-right: 2%;
}
.full{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}