* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.menuItems {
    background-color: #fff;
    margin: 1%;
    padding: 1%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 3px rgba(150,168,157,0.72);
}

.mapItem {
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: medium;
    /* height: 10pc; */
    background-color: rgb(250, 253, 248);
    color: #348276;
    cursor: pointer;
}

.pageHeading {
    margin-left: 1pc;
    margin-top: 1pc;
}

.stack {
    margin: 2%;
}

.stackHeading {
    /* margin-left: 1pc; */
    /* margin-top: 1pc; */
}

.welcome {
    /* margin-bottom: 1rem; */
    height: 100%;
}

.datePicker {
    width: 50%;
}

.unitsContainer {
    margin: 1% 1% 0% 0%;
    /* margin-top: 2%; */
}

.historyTable {
    /* margin: 1%; */
    padding: 2%;
}

.vrline {
    border-left: 2px solid green;
    height: 100%;
    width: 0%;
}

.hrline {
    border-top: 1px solid grey;
    width: 90%;
    margin-left: 5%;
}

.datePickerGrid {
    /* height: 50%; */
}

.dialogGrid {
    margin: 1% 2% 1% 2%;
    width: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: #000000; */
    height: 100%;
}

.welcome_title {
    font-weight: bolder;
    color: #0D6659;
}

.business_title {
    /* font-weight: bolder; */
    color: #003E35;
    font-size: 1.5rem;
}

.welcome_image {
    width: 400px;
    height: 100px;
    margin-top: 2rem;
}

.description {
    width: 90%;
    height: 100px;
    color: #0D6659;

}

.main_btns {
    margin: 1rem;
}

.started_button {
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
}