* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_card {
    /* margin-top: 10px; */
    border: 1px solid #ccc;
    /* margin-bottom: 1rem; */
    height: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: bolder;
    font-size: 1.5rem;
}

.description {
    margin: 1rem;
    font-size: 1.2rem;
}

.inner_terms_condition {
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #ccc; 
    width: 90%;
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
}

.checkbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 50px;
}

.terms_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}