@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.title {
  color: #0099ff; 
  font-size: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.sub_title {
  margin: 0.5rem; 
  color: #0099ff; 
  font-size: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.cardSmall {
  margin-right: auto;
  margin-left:  auto;
  padding: 2%;
  width: 80%;
  margin-bottom: 50vh;
}
.card {
    width: 95%;
    height: 95%;
    margin-right: auto;
    margin-left:  auto;
    padding: 2%;
}

.textfield {
    display:flex; 
    flex-direction:row;
}

.buttons {
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 639px) {
    body {
        background-color: linear-gradient(320deg, #101010 0%, #2E8DE1 100%);
        width: 150vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
      }
      .textfield {
        display: flex;
        flex-direction: column;
      }
      .card {
        margin-left: auto;
        margin-right: auto;
      }
}

@media screen and (max-width: 763px) {
  .textfield {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
   .lastTextfield {
    margin-left: auto;
    margin-right: auto;
  } 
  .card {
    margin-left: auto;
    margin-right: auto;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif;
  }  
}