.navItem {
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}
  
.navItem:hover {
    color: black;
    background-color: #ffff;
}
  
.activeNavItem {
    color: black;
    background-color:#003E35;
}
  
.navIcon {
    color: #d1d5db;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}
  
.navLabel {
    color: #d1d5db;
    font-size: 1rem;
}
  
.navItemHeaderButton {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
}
  
.navItemHeaderChevron {
    color: #d1d5db;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    transition: all 0.25s;
}
  
.chevronExpanded {
    transform: rotate(180deg);
}
  
.navChildrenBlock {
    /* background: #bdd2cc; */
    /* background-color: hsl(226, 71%, 36%); */
}