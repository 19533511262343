.sidebar {
    background: #3b7b6f;
    /* background-color: #538cc6; */
    height: 100%;
    margin-top: auto;
    /* width: 100%; */
}

/* media query for phones and tablets 400px */
/* @media screen and (max-width: 400px) {
  .sidebar {
    min-height: 140vh;
  }   
} */

/* media query for phones and tablets 500px */
/* @media screen and (max-width: 500px)  {
  .sidebar {
    min-height: 125vh;
  }
} */

/* media query for phones and tablets 600px */
/* @media screen and (max-width: 600px) {
  .sidebar {
    height: 190vh;
  }
} */

/* media query for phones and tablets 700px */
/* @media screen and (max-width: 700px) {
  .sidebar {
    min-height: 144vh;
  }
} */