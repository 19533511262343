@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 1rem; */
    border-radius: 15px;
    background-color: black;
    border: 6px solid #0D6659;
}

.inner_card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.vchain {
    width: 150px;
    height: 150px;
    margin: 20px;
    background-color: black;
    float: left;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

.vchained_image {
  width: 70%;
  border:"2px solid red"
}  
  
.container {
    padding: 2px 16px;
    color: #fff;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    width: 40%;
    font-family: 'Roboto', sans-serif;
}

.container h6 {
  text-align: right;
}

.horizontal_line {
    color: #fff;
}

/* main card c.s.s start here */
.main_card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/* main card c.s.s ends here */

/* second div c.s.s start here */
.cards {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 50%;
    border-radius: 15px;
    background-color: #0D6659;
    margin: 10px;
    border: 2px solid #ccc;
  }
  
  .cards:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  img {
    border-radius: 5px 5px 0 0;
  }
  
  .containers {
    padding: 2px 16px;
  }

  .containers h6 {
    color: #fff;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
  }

  .inner_box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #0b86df;
  }

  /* inner chart c.s.s start here */
  .inner_chart {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .raw_material {
    /* width: 135px; */
    height: 10%;
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .manufacturing {
    height: 10%;
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .packaging {
    height: 10%;
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .transportation {
    height: 10%;
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  /* inner chart c.s.s ends here */

/* second div c.s.s ends here */

/* third div c.s.s start here */
.cards_one {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 15px;
  background-color: #0D6659;
  margin: 10px;
  border: 2px solid #ccc;
}

.cards_one:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container_one {
  padding: 2px 16px;
}

.container_one h6 {
  color: #fff;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
}
/* third div c.s.s ends here */

/* loader spinner c.s.s */
.loader {
  border: 16px solid #fff;
  border-radius: 50%;
  border-top: 16px solid #0D6659;
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader h6 {
  text-align: center;
  /* font-size: 1.2rem; */
  font-size: medium;
  margin-top: 0.5rem;
}

/* fourth div start here */
.cards_two {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 95%;
  border-radius: 5px;
  background-color: #0D6659;
  /* top, left, right, bottom property */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  border-radius: 15px;
}

.card_image {
  width: 20%;
}

.cards_two img {
  width: 180px;
  height: 160px;
  border-radius: 15px;
  margin: 10px;
}

.container_two {
  padding: 2px 16px;
  width: 65%; 
}

.container_description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container_two h6 {
  color: #fff;
  padding: 10px;
  text-align: left;
  text-indent: -8px;
  font-family: 'Roboto', sans-serif;
}

.inner_heading {
  font-size: 1.3rem;
}

.inner_headings {
  font-size: 1.1rem;
}

.inner_container {
  text-align: center;
}

.container_two p {
  color: #fff;
  padding: 2px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
}
.container_para {
  line-height: 1.5;
}
/* fourth div ends here */


/* media query for phones start here */
@media only screen and (max-width:1300px)  {
  /* body{
    background-color: lightblue;
    border: 1px solid red;
  } */
  .container {
    font-size: 1rem;
  }
  .card {
    width: 100%;
  }
  .main_card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .cards {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  .cards_one {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  #myChart {
    margin-left: auto;
    margin-right: auto;
  }
  .inner_box h6 {
    font-size: 1.5rem;
  }
  .inner_headings {
    font-size: 1.3rem;
  }
  .inner_container {
    font-size: 1.5rem;
  }
  .cards_two {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .card_image {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  #new_image {
    width: 100%;
  }
  .inner_heading {
    font-size: 1.5rem;
  }
  .container_description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 130%;
    margin: 0.5rem;
  }
  .container_description p {
    font-size: 1.3rem;
  }
  .container_para {
    width: 160%;
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3rem;
  }
}
/* medai query for phones ends here */